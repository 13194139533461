import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-find-your-car',
  templateUrl: './find-your-car.component.html',
  styleUrls: ['./find-your-car.component.scss']
})
export class FindYourCarComponent {
  public allMakes: any = [];
  public modelList: any = [];
  public yearList: any = [];
  public form: FormGroup;
  public isSubmit = false

  constructor(private service: CommonService,private fb: FormBuilder,private router:Router) {
    // document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.service.scrollToTop()
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= 1960; i--) {
      this.yearList.push(i);
    }
    this.getAllMake();
    this.form = this.fb.group({
      makeName: ['', Validators.required], 
      year: ['', Validators.required],    
      modelName: ['', Validators.required], 
      vinNumber: ['', Validators.required]
    });
  }

  // get all car brand
  getAllMake() {
    this.allMakes = [];
    this.service.showSpinner();
    this.service.getAllMakeApi().subscribe(
      (res: any) => {
        if (res && res.data) {
          this.allMakes = res.data;
          this.service.hideSpinner();
        }
      },
      (error) => {
        console.log(error);
        this.service.hideSpinner();
      }
    );
  }
  
  

  getModel(event: Event) {
    this.service.showSpinner();
    if (event && event.target) {
      if ('value' in event.target) {
        this.service.getModelByMakeName(this.form.get('makeName')?.value)
          .subscribe((res: any) => {
            if (res && res.data) {
              this.modelList = res.data;
  
              this.service.hideSpinner();
            }
          }, (error) => {
            this.service.hideSpinner();
          });
      }
    }
  }
  
  
  onSubmit(){
    if(this.form.invalid){
      this.isSubmit = true
      return
    }
    let carData = {      
      carName: this.form.value.makeName,
      modelYear: this.form.value.year,
      modelName: this.form.value.modelName,
      vinNumber: this.form.value.vinNumber,
    }
    if(this.service.isBrowserPlateformId()){

      localStorage.removeItem('carFormData');
      localStorage.setItem('carData', `${JSON.stringify(carData)}`)  
      document.body.style.overflow = 'initial';
    }
    this.router.navigate(['seller/add-car'])
  }
  // getYear(event: Event) {
  //   if (event && event.target) {
  //     if ('value' in event.target) {
  //       let url = `https://car-api2.p.rapidapi.com/api/years?model=${event.target.value}`
  //       this.service.vinApi(url).subscribe((res: any) => {
  //         this.yearList = res
  //       })
  //     }
  //   }

  // }
}
